import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const endpoint = 'https://cat.imonline.ca/backend/backend.php';

const SavedLicenses = ({ savedLicenses, editingIndex, handleEditClick, licenseInfo, handleDateChange, fetchAndRefreshLicenses,handleDeleteLicense }) => {
    const confirmAndDelete = (id) => {
        const shouldDelete = window.confirm('Are you sure you want to delete this license?');
    
        if (shouldDelete) {
          handleDeleteLicense(id);
        }
      };
  // Function to check if a date is within 3 weeks from now
  const isWithin3Weeks = (date) => {
    const threeWeeksFromNow = new Date();
    threeWeeksFromNow.setDate(threeWeeksFromNow.getDate() + 3 * 7); // Adding 3 weeks

    return date <= threeWeeksFromNow;
  };
  return (
    <div className="row">
      {savedLicenses.map((license, index) => {
        const isExpiryWithin3Weeks = isWithin3Weeks(new Date(license.expiryDate));
  
        return (
          <div
            key={index}
            className={`col-md-4 mb-4 ${isExpiryWithin3Weeks ? 'orange-bg' : ''}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleEditClick(index)}
          >
            <div className={`card ${isExpiryWithin3Weeks ? 'orange-card' : ''}`}>
              <div className="card-body">
                {editingIndex === index ? (
                  <>
                    <div className="form-group">
                      <label htmlFor={`editedLicenseNumber${index}`}>License Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`editedLicenseNumber${index}`}
                        value={licenseInfo.licenseNumber}
                        onChange={(e) =>
                          handleDateChange(e.target.value, 'licenseNumber')
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor={`editedState${index}`}>State</label>
                      <input
                        type="text"
                        className="form-control"
                        id={`editedState${index}`}
                        value={licenseInfo.state}
                        onChange={(e) =>
                          handleDateChange(e.target.value, 'state')
                        }
                      />
                    </div>
                    {/* Editable date fields */}
                    <div className="form-group">
                      <label>Applied date:</label>
                      <DatePicker
                        selected={licenseInfo.appliedDate}
                        onChange={(date) => handleDateChange(date, 'appliedDate')}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Start date:</label>
                      <DatePicker
                        selected={licenseInfo.startDate}
                        onChange={(date) => handleDateChange(date, 'startDate')}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label>Expiry date:</label>
                      <DatePicker
                        selected={licenseInfo.expiryDate}
                        onChange={(date) => handleDateChange(date, 'expiryDate')}
                        dateFormat="MMMM d, yyyy"
                        className="form-control"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <h5 className="card-title">License Number: {license.licenseNumber}</h5>
                    <p className="card-text">State: {license.state}</p>
                    <p className="card-text">
                      Applied Date: {new Date(license.appliedDate).toLocaleDateString()}
                    </p>
                    <p className="card-text">
                      Start Date: {new Date(license.startDate).toLocaleDateString()}
                    </p>
                    <p className="card-text">
                      Expiry Date: {new Date(license.expiryDate).toLocaleDateString()}
                    </p>
                    <button
                      className="btn btn-danger"
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmAndDelete(license.id);
                      }}
                    >
                      Delete
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
  
};

export default SavedLicenses;
