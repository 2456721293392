import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SavedLicenses from './SavedLicenses';
const endpoint = 'https://cat.imonline.ca/backend/backend.php';

const LicenseForm = () => {
  const [licenseInfo, setLicenseInfo] = useState({
    licenseNumber: '',
    state: '',
    appliedDate: new Date(),
    startDate: new Date(),
    expiryDate: new Date(),
  });

  const [savedLicenses, setSavedLicenses] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1); // Index of the license being edited

  useEffect(() => {
    fetchSavedLicenses();
  }, []);

  const handleDeleteLicense = async (id) => {
    try {
      const response = await fetch(`${endpoint}?action=deleteLicense&id=${id}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        console.log('License deleted successfully');
        fetchAndRefreshLicenses(); // Fetch and refresh licenses after deletion
      } else {
        console.error('Error deleting license:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting license:', error);
    }
  };

  const fetchSavedLicenses = async () => {
    try {
      const response = await fetch(`${endpoint}?action=getLicenses`);
      const data = await response.json();
      setSavedLicenses(data);
    } catch (error) {
      console.error('Error fetching licenses:', error);
    }
  };
  const fetchAndRefreshLicenses = async () => {
    try {
      const response = await fetch(`${endpoint}?action=getLicenses`);
      const data = await response.json();
      setSavedLicenses(data);
    } catch (error) {
      console.error('Error fetching licenses:', error);
    }
  };

  const handleDateChange = (date, field) => {
    setLicenseInfo((prevInfo) => ({ ...prevInfo, [field]: date }));
  };

  const handleEditClick = (index) => {
    setEditingIndex(index);
    const selectedLicense = savedLicenses[index];
    setLicenseInfo({
      id: selectedLicense.id, // Add this line to include the id property
      licenseNumber: selectedLicense.licenseNumber,
      state: selectedLicense.state,
      appliedDate: new Date(selectedLicense.appliedDate),
      startDate: new Date(selectedLicense.startDate),
      expiryDate: new Date(selectedLicense.expiryDate),
    });
  };

//   const handleSaveEdit = () => {
//     if (editingIndex !== -1) {
//       const updatedLicenses = [...savedLicenses];
//       updatedLicenses[editingIndex] = licenseInfo;
//       setSavedLicenses(updatedLicenses);
//       setEditingIndex(-1);
//       setLicenseInfo({
//         licenseNumber: '',
//         state: '',
//         appliedDate: new Date(),
//         startDate: new Date(),
//         expiryDate: new Date(),
//       });
//     }
//   };
const handleSaveEdit = async () => {
    if (editingIndex !== -1) {
      const updatedLicenses = [...savedLicenses];
      updatedLicenses[editingIndex] = licenseInfo;
        console.log(updatedLicenses[editingIndex].id);
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'updateLicense', // Change this to the appropriate action
            id: updatedLicenses[editingIndex].id, // Assuming you have an "id" field
            licenseNumber: licenseInfo.licenseNumber,
            state: licenseInfo.state,
            appliedDate: licenseInfo.appliedDate.toISOString(),
            startDate: licenseInfo.startDate.toISOString(),
            expiryDate: licenseInfo.expiryDate.toISOString(),
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log('Response: ', data);
          setSavedLicenses(updatedLicenses);
          setEditingIndex(-1);
          setLicenseInfo({
            licenseNumber: '',
            state: '',
            appliedDate: new Date(),
            startDate: new Date(),
            expiryDate: new Date(),
          });
          
        } else {
          console.error('Error updating license:', response.statusText);
        }
      } catch (error) {
        console.error('Error updating license:', error);
      }
    }
  };
  

  const handleSubmit = async () => {
    if (editingIndex !== -1) {
      await handleSaveEdit(); // Save edits if editing
    } else {
      try {
        const response = await fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'insertLicense',
            licenseNumber: licenseInfo.licenseNumber,
            state: licenseInfo.state,
            appliedDate: licenseInfo.appliedDate.toISOString(),
            startDate: licenseInfo.startDate.toISOString(),
            expiryDate: licenseInfo.expiryDate.toISOString(),
          }),
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Response: ', data);
          fetchAndRefreshLicenses();
          alert('License added successfully!');
         
        } else {
          console.error('Error submitting license:', response.statusText);
        }
      } catch (error) {
        console.error('Error submitting license:', error);
      }
    }
  };

  return (
    <div>
      <h2>License Information</h2>
      <div className="form-group">
        <label htmlFor="licenseNumber">License Number</label>
        <input
          type="text"
          className="form-control"
          id="licenseNumber"
          value={licenseInfo.licenseNumber}
          onChange={(e) =>
            setLicenseInfo((prevInfo) => ({
              ...prevInfo,
              licenseNumber: e.target.value,
            }))
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="state">State</label>
        <input
          type="text"
          className="form-control"
          id="state"
          value={licenseInfo.state}
          onChange={(e) =>
            setLicenseInfo((prevInfo) => ({
              ...prevInfo,
              state: e.target.value,
            }))
          }
        />
      </div>
  
      {/* Editable date fields */}
      <div className="form-group">
        <label>Applied date:</label>
        <DatePicker
          selected={licenseInfo.appliedDate}
          onChange={(date) => handleDateChange(date, 'appliedDate')}
          dateFormat="MMMM d, yyyy"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Start date:</label>
        <DatePicker
          selected={licenseInfo.startDate}
          onChange={(date) => handleDateChange(date, 'startDate')}
          dateFormat="MMMM d, yyyy"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Expiry date:</label>
        <DatePicker
          selected={licenseInfo.expiryDate}
          onChange={(date) => handleDateChange(date, 'expiryDate')}
          dateFormat="MMMM d, yyyy"
          className="form-control"
        />
      </div>
  
      {editingIndex !== -1 ? (
        <div className="mb-3">
          <button className="btn btn-success mr-2" onClick={handleSaveEdit}>
            Save Edit
          </button>
          <button className="btn btn-secondary" onClick={() => setEditingIndex(-1)}>
            Cancel Edit
          </button>
        </div>
      ) : (
        <button className="btn btn-primary" onClick={handleSubmit}>
          Submit
        </button>
      )}
  
      <h2 className='mt-3'>Saved Licenses</h2>
      <SavedLicenses
        savedLicenses={savedLicenses}
        editingIndex={editingIndex}
        handleEditClick={handleEditClick}
        licenseInfo={licenseInfo}
        handleDateChange={handleDateChange}
        fetchAndRefreshLicenses={fetchAndRefreshLicenses} 
        handleDeleteLicense={handleDeleteLicense}
        />
    </div>
  );
};

export default LicenseForm;
