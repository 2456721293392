import React, { useState, useEffect } from 'react';
import Login from './Login';
import LicenseForm from './LicenseForm';

const App = () => {
  const [loggedIn, setLoggedIn] = useState(false);

  // Check local storage for logged-in status when the app loads
  useEffect(() => {
    const isLoggedIn = localStorage.getItem('loggedIn');
    if (isLoggedIn) {
      setLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem('loggedIn', 'true'); // Store logged-in status in local storage
    setLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('loggedIn'); // Remove logged-in status from local storage
    setLoggedIn(false);
  };

  const handleLicenseSubmit = (licenseInfo) => {
    // Call the PHP API here to save licenseInfo to the database
    console.log('License info submitted:', licenseInfo);
  };

  return (
    <div>
      {loggedIn ? (
        <>
        <div className='d-flex mb-4'>
              <h2 className='text-primary me-3'>Welcome Cat <i className="bi bi-emoji-smile"></i></h2>
              <button className='btn btn-success' onClick={handleLogout}><i className="bi bi-box-arrow-left"></i> Logout</button>
              
        </div>
          <LicenseForm onSubmit={handleLicenseSubmit} />
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
};

export default App;
