import React, { useState } from 'react';

const Login = ({ onLogin }) => {
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Perform password validation here
    if (password === 'catafido') {
      onLogin();
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <div className='p-3'>
      <h2>Login</h2>
      <div className='d-flex mt-2'>
            <input className='form-control me-2'
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className='btn btn-warning' onClick={handleLogin}>Login</button>
      </div>
     
    </div>
  );
};

export default Login;
